import React from "react"
import { Link, graphql } from "gatsby"

import BioFull from "../components/bio-full"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Product and User Experience Design Leader" />
      <BioFull />
      <h2>Writings</h2>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article
            key={node.fields.slug}
            className={node.frontmatter.pinned && `pinned-post`}
          >
            <header>
              <h3 className="article-title">
                <Link to={node.fields.slug}>{title}</Link>
              </h3>
              {node.frontmatter.pinned && (
                <div>
                  <span
                    role="img"
                    aria-label="Pinned post"
                    className="pinned-label"
                  >
                    ★
                  </span>
                </div>
              )}
            </header>
            <small className="article-metadata">
              {`${node.timeToRead} min read `}&middot;
              {` ${node.frontmatter.date}`}
            </small>
            <section>
              <p
                style={{ marginTop: `.25rem`, marginBottom: `.25rem` }}
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: {
        fields: [frontmatter___pinned, frontmatter___date]
        order: [ASC, DESC]
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            pinned
          }
          timeToRead
        }
      }
    }
  }
`
