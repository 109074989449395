/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const SafeLinkWrapper = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

const BioFull = () => {
  const data = useStaticQuery(graphql`
    query BioQueryFull {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 160, height: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter
          }
          description
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <header style={{ marginBottom: `4rem` }}>
      <section
        style={{ textAlign: `center` }}
        itemScope
        itemType="http://schema.org/Person"
      >
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author.name}
          className="profileImageWrapper"
        />
        <h1 itemProp="name" id="name">
          {author.name}
        </h1>
        <h3 itemProp="jobTitle" id="job-title">
          Product and User Experience (UX) Design Leader with expertise in
          human-centered Interaction Design, Information Architecture, Content
          Strategy, and{" "}
          <span style={{ whiteSpace: "nowrap" }}>Product Management.</span>
        </h3>
        <meta
          itemProp="description"
          content={data.site.siteMetadata.description}
        />
        <p id="contact">
          <SafeLinkWrapper href="https://ca.linkedin.com/in/wrightaj">
            LinkedIn
          </SafeLinkWrapper>{" "}
          &middot;{" "}
          <SafeLinkWrapper href="https://www.slideshare.net/andr3wjwright">
            SlideShare
          </SafeLinkWrapper>{" "}
          {/*&middot; <SafeLinkWrapper href="https://ajw.design/blog/">Blog</SafeLinkWrapper>{" "}*/}
          &middot;{" "}
          <SafeLinkWrapper href="https://medium.com/@andrewjwright/">
            Medium
          </SafeLinkWrapper>
          &middot;{" "}
          <SafeLinkWrapper href="https://x.com/andrewjwright">
            X (Twitter)
          </SafeLinkWrapper>{" "}
          &middot;{" "}
          <a itemProp="email" href="mailto:the.concept.blend@gmail.com">
            Email
          </a>{" "}
        </p>
      </section>
      <p id="mission">
        <strong>Interacting with technology shouldn't be complicated.</strong>{" "}
        My mission is to create digital experiences that people find simple,
        meaningful, and pleasurable. I do this by understanding and designing
        for their needs, behaviours, and mental model.
      </p>
      <hr />
      <p>Some of the companies I've worked with include:</p>
      <ul>
        <li>
          <SafeLinkWrapper itemprop="url" href="https://symend.com">
            Symend
          </SafeLinkWrapper>{" "}
          – Behavioral Science-based customer engagement platform (current role)
        </li>
        <li>
          <SafeLinkWrapper href="https://www.hibrightside.ca/">
            Brightside by ATB
          </SafeLinkWrapper>{" "}
          – Fintech startup
        </li>
        <li>
          <SafeLinkWrapper href="https://www.atb.com/">
            ATB Financial
          </SafeLinkWrapper>
        </li>
        <li>
          <SafeLinkWrapper href="https://www.yvr.ca/">
            YVR (Vancouver International Airport)
          </SafeLinkWrapper>
        </li>
        <li>
          <SafeLinkWrapper href="https://www.bmo.com/">
            BMO Bank of Montreal
          </SafeLinkWrapper>
        </li>
        <li>
          <SafeLinkWrapper href="https://www.cpr.ca/">
            Canadian Pacific (CP)
          </SafeLinkWrapper>
        </li>
        {/* <li>
          <SafeLinkWrapper href="https://www.canadasoilsands.ca/">Canada's Oil Sands</SafeLinkWrapper> and{" "}
          <SafeLinkWrapper href="https://www.canadasoilsands.ca/">Canada's Natural Gas</SafeLinkWrapper>{" "}
          (sibling sites)
        </li> */}
      </ul>
      <hr />
    </header>
  )
}

export default BioFull
